<template>
  <unauthorized-access v-if="!isAuthorized">
    You are signed in as {{ currentUser.email }}.
  </unauthorized-access>
  <div
    v-else
    class="row"
  >
    <div class="col">
      <h3>Tags</h3>

      <div class="d-grid gap-2 mb-2">
        <Alert
          :message="success"
          status="success"
          @alert-displayed="resetSuccess"
        />
        <nav aria-label="Alphabet Navigation">
          <ul class="pagination pagination-sm">
            <li
              v-for="alphabet in alphabets"
              :key="alphabet"
              class="page-item"
            >
              <a
                class="page-link"
                :class="{ active: alphabet === letter }"
                href="#"
                @click.prevent="fetchtags(alphabet)"
              >
                {{ alphabet.toUpperCase() }}
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div v-if="tags">
        <h4>
          {{ tags.length }}
          tags starting with
          {{ letter.toUpperCase() }}
        </h4>
        <div class="list-group">
          <button
            v-for="tag in tags"
            :key="tag.id"
            type="button"
            class="list-group-item list-group-item-action"
            @click="edit(tag)"
          >
            <div class="d-flex justify-content-between">
              <span>{{ tag.name }}</span>
              <span>
                {{ tag.terms.length }} term{{ tag.terms.length > 1 ? 's' : '' }}
              </span>
            </div>
          </button>
        </div>
      </div>
      <div v-else>
        <h4> Loading</h4>
        <Alert
          status="danger"
          :message="errorMessage"
        />
      </div>
    </div>
    <Edit
      v-bind="{...selectedTag}"
      ref="modal"
      :tags="tagsForSelect"
      @wts:tag-saved="updateTag"
      @wts:tag-destroyed="removeTag"
    />
  </div>
</template>

<script>
import { trackError } from '@/helpers/google-analytics-tracker';
import AdminAuthorizationCheck from '@/services/admin/authorization-check';
import TagService from '@/services/admin/tag.service.js';
import Alert from '@/components/admin/Alert.vue';
import Edit from './Edit';
import UnauthorizedAccess from '../UnauthorizedAccess.vue';

export default {
  name: 'Tag',
  components: {
    Alert,
    Edit,
    UnauthorizedAccess
  },
  setup() {
    return {
      ...AdminAuthorizationCheck()
    };
  },
  data() {
    return {
      alphabets: 'abcdefghijklmnopqrstuvwxyz…'.split(''),
      letter: "",
      tags: [],
      errorMessage: null,
      selectedTag: null,
      success: "",
    }
  },
  computed: {
    tagsForSelect() {
      const selectedTagId = this.selectedTag?.id;
      return this.tags.filter((cat) => cat.id !== selectedTagId)
    },
    startingWith() {
      const regex = /^[a-zA-Z]/
      if (regex.test(this.letter)) {
        return this.letter.toUpperCase();
      } else {
        return 'other characters';
      }
    }
  },
  async mounted() {
    try {
      const letter = this.$route.query.letter || 'a';
      const tags = await this.fetchtags(letter);
      if (!tags.empty) {
        this.tags = tags
      }
    }
    catch(e) {
      trackError(e);
    }
  },
  methods: {
    async fetchtags(letter) {
      this.$router.push({ path: '/admin/tag', query: { letter } });
      this.letter = letter;

      this.errorMessage = null;
      try {
        const resp = await TagService.fetchAll(letter);
        const { tags } = resp.data;
        this.tags = tags;
        return this.tags;
      }
      catch (error) {
        this.errorMessage = error.message;
        console.error('Error: ', error.message);
        trackError(error);
      }
    },
    updateTag(tag) {
      this.success = "Successfully Updated!"
      const index = this.tags.findIndex((t) => t.id == tag.id)
      if (index) {
        this.tags[index] = tag
      }
      else {
        this.tags.push(tag);
      }
    },
    removeTag(tag) {
      this.success = "Successfully Deleted!"
      const index = this.tags.findIndex((t) => t.id == tag.id)
      if (index) {
        this.tags.splice(index, 1);
      }
    },
    resetSuccess() {
      setTimeout(() => { this.success = "" }, 5000);
    },
    edit(tag) {
      const { id, name: initialName } = tag;
      this.selectedTag = { id, initialName };
      this.$refs.modal.show()
    },
  },
}
</script>
