<template>
  <div
    id="edit"
    :data-id="id"
    class="modal fade"
    tabindex="-1"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="id">
            {{ name }}
          </div>
          <div v-else>
            New Tag
          </div>
        </div>

        <div class="modal-body">
          <Alert
            :message="error"
            status="danger"
          />
          <div v-show="id">
            ID: {{ id }}
          </div>
          <Form
            @submit="submit"
          >
            <div class="mb-3 row g-2">
              <label
                for="tag-name"
                class="col-auto col-form-label"
              >Tag Name</label>
              <div class="col-auto">
                <Field
                  id="tag-name"
                  v-model="name"
                  type="text"
                  name="name"
                  autocomplete="off"
                  class="form-control"
                  :rules="validateName"
                  required="required"
                />
                <ErrorMessage name="name" />
              </div>
            </div>
            <div class="mb-3 row g-2">
              {{ terms.length }} terms with this tag
              <ul
                v-if="terms.length"
                class="list-group"
              >
                <li
                  v-for="term in terms"
                  :key="term.id"
                  class="list-group-item"
                >
                  {{ term.title }}
                </li>
              </ul>
            </div>
          </Form>
        </div>

        <div class="modal-footer d-flex justify-content-between">
          <div class="start-side">
            <button
              type="button"
              class="btn btn-danger"
              @click="getConfirmDelete"
            >
              Delete
            </button>
          </div>
          <div class="end-side">
            <button
              type="button"
              class="btn btn-primary"
              @click="save"
            >
              Save
            </button>
            <button
              type="button"
              class="btn btn-secondary mx-2"
              aria-label="close"
              @click="cancel"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete tag confirmation modal -->
    <div
      id="confirm-delete"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-sm"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete tag confirmation
            </h5>
            <button
              type="button"
              class="btn btn-close"
              aria-label="Close"
              @click="cancelConfirmDelete()"
            />
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete the tag {{ name }}?</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              @click="destroy"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="cancelConfirmDelete()"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagService from '@/services/admin/tag.service.js';
import {Form, Field, ErrorMessage} from "vee-validate";
import { Modal, } from 'bootstrap';
import * as yup from 'yup';
import Alert from '@/components/admin/Alert.vue';
import { trackError } from '@/helpers/google-analytics-tracker';
const validateName = yup.string().required().min(1).nullable()

export default {
  name: "EditTag",
  components: {
    Alert,
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    id: {
      type: Number,
      default() { return null; },
    },
    initialName: {
      type: String,
      default() { return null; },
    },
  },
  emits: ['wts:tag-saved', 'wts:tag-destroyed'],
  data() {
    return {
      name: null,
      moon: null,
      error: "",
      terms: [],
      validateName,
      confirmDelete: null,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.id) {
          this.fetch();
        }
      }
    },
    initialName(newVal) {
      this.name = newVal;
    }
  },
  mounted() {
    this.moon = new Modal(document.getElementById('edit'))
    this.confirmDelete = new Modal(document.getElementById('confirm-delete'))
  },
  methods: {
    submit() {
      this.save();
    },
    async fetch() {
      try {
        const resp = await TagService.find(this.id);
        const { name, terms } = resp.data;
        this.name = name;
        this.terms = terms;
      }
      catch(e) {
        this.error = e.message;
        console.log("Error: ", this.error)
        trackError(e);
      }
    },
    async save() {
      try {
        const { id, name } = this;
        const resp = await TagService.save(
          { id, name }
        );
        console.log("saved!");
        this.$emit('wts:tag-saved', resp.data)
        this.moon.hide();
      }
      catch(e) {
        this.error = e.message;
        console.log("Error: ", this.error)
        trackError(e);
      }
    },
    async cancel() {
      this.error = null;
      this.moon.hide();
    },
    async destroy() {
      this.error = null;
      try {
        this.confirmDelete.hide();
        const resp = await TagService.destroy(this.id);
        console.log("destroyed!");
        this.$emit('wts:tag-destroyed', resp.data)
        this.moon.hide();
      }
      catch(e) {
        this.error = e.message;
        console.log("Error: ", this.error)
        trackError(e);
      }
    },
    show() {
      this.moon.show();
    },
    getConfirmDelete() {
      this.confirmDelete.show();
    },
    cancelConfirmDelete() {
      this.confirmDelete.hide();
    },
  }
}
</script>
