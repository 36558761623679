import axios from 'axios';
import authHeader from '@/services/auth-header';

const API_URL = `${process.env.VUE_APP_API_URL}/admin/tags`
const headers = () => ({ headers: authHeader() })

export class TagService {
  fetchAll(letter) {
    return axios.get(API_URL, Object.assign({ params: { letter } }, headers() ));
  }
  find(id) {
    return axios.get(`${API_URL}/${id}`, headers())
  }
  save(tag) {
    const { id } = tag;
    if (id) {
      return axios.patch(`${API_URL}/${id}`, { tag }, headers());
    }
    else {
      return axios.post(`${API_URL}`, { tag }, headers());
    }
  }
  destroy(id) {
    return axios.delete(`${API_URL}/${id}`, headers());
  }
}
export default new TagService()
